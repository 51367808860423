import React, { useContext, useState } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";

import { AppContext } from "~context/AppContext";

const Header = () => {
  const appContext = useContext(AppContext);
  const [headerActive, setHeaderActive] = useState(false);

  //

  const handleScroll = index => {
    setHeaderActive(false);
    clearAllBodyScrollLocks();

    appContext.sectionRefs[index].current.scrollIntoView({
      behavior: `smooth`
    });
  };

  //

  return (
    <>
      {appContext.device !== `mobile` ? (
        <header className="header h-screen fixed right-0 top-0 bottom-0 z-10">
          <ul className="flex flex-col h-full justify-between b1 text-navy bg-bone font-light">
            <li>
              <button
                className={`block whitespace-no-wrap ${
                  appContext.activeSection === 0 ? `font-medium` : ``
                }`}
                type="button"
                onClick={() => handleScroll(0)}
              >
                {appContext.activeSection === 0 ? `●` : `○`} Home
              </button>
            </li>
            <li>
              <button
                className={`block whitespace-no-wrap ${
                  appContext.activeSection === 1 ? `font-medium` : ``
                }`}
                type="button"
                onClick={() => handleScroll(1)}
              >
                {appContext.activeSection === 1 ? `●` : `○`} Projects
              </button>
            </li>
            <li>
              <button
                className={`block whitespace-no-wrap ${
                  appContext.activeSection === 2 ? `font-medium` : ``
                }`}
                type="button"
                onClick={() => handleScroll(2)}
              >
                {appContext.activeSection === 2 ? `●` : `○`} Experiences
              </button>
            </li>
          </ul>
        </header>
      ) : (
        <button
          type="button"
          className={`header__mobile bg-bone fixed right-0 top-0 flex flex-col justify-center items-center z-50 ${
            headerActive ? `header__active` : ``
          }`}
          onClick={() => {
            if (headerActive) {
              clearAllBodyScrollLocks();
            } else {
              disableBodyScroll();
            }

            setHeaderActive(!headerActive);
          }}
        >
          <div className="header__mobile__line--1 absolute bg-navy w-3/5"></div>
          <div className="header__mobile__line--2 absolute bg-navy w-3/5"></div>
        </button>
      )}

      <div
        className={`header__menu fixed top-0 left-0 right-0 bottom-0 bg-bone text-navy z-40 flex flex-col whitespace-no-wrap ${
          headerActive ? `header__active p-4` : ``
        }`}
      >
        {headerActive && (
          <ul>
            <li
              className="pt-16 mb-6 animation-appear-from-top"
              style={{ animationDelay: `100ms` }}
            >
              <button
                type="button"
                className={`f2 uppercase ${
                  appContext.activeSection === 0 ? `font-bold` : `font-medium`
                }`}
                onClick={() => handleScroll(0)}
              >
                {appContext.activeSection === 0 ? `● ` : `○ `}Home
              </button>
            </li>

            <li
              className="my-6 animation-appear-from-top"
              style={{ animationDelay: `200ms` }}
            >
              <button
                type="button"
                className={`f2 uppercase ${
                  appContext.activeSection === 1 ? `font-bold` : `font-medium`
                }`}
                onClick={() => handleScroll(1)}
              >
                {appContext.activeSection === 1 ? `● ` : `○ `}Projects
              </button>
            </li>

            <li
              className="my-6 animation-appear-from-top"
              style={{ animationDelay: `300ms` }}
            >
              <button
                type="button"
                className={`f2 uppercase ${
                  appContext.activeSection === 2 ? `font-bold` : `font-medium`
                }`}
                onClick={() => handleScroll(2)}
              >
                {appContext.activeSection === 2 ? `● ` : `○ `}Experience
              </button>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Header;
