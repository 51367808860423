/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import ClientRender from "~components/ClientRender";
import Header from "~components/Header";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

import { AppContext } from "~context/AppContext";

class IndexPageComponent extends Component {
  state = {
    activeProjectIndex: 0
  };

  projects = [];

  //
  // Refs

  sectionRefs = [];

  //

  componentDidMount() {
    const { allMarkdownRemark, appContext } = this.props;

    window.scrollTo(0, 0);

    //
    // Create Refs

    this.sectionRefs.push(
      React.createRef(),
      React.createRef(),
      React.createRef()
    );

    appContext.setSectionRefs(this.sectionRefs);

    appContext.activeSection = 0;

    //
    // Get all projects

    allMarkdownRemark.edges.forEach(markdownRemark => {
      if (markdownRemark.node.frontmatter.templateKey === `project-page`) {
        markdownRemark.node.frontmatter.slug = markdownRemark.node.fields.slug;
        markdownRemark.node.frontmatter.date = new Date(
          markdownRemark.node.frontmatter.date
        );
        this.projects.push(markdownRemark.node.frontmatter);
      }
    });

    this.projects.sort((a, b) => b.date - a.date);
  }

  //

  handleProjectMouseOver = index => {
    this.setState({ activeProjectIndex: index });
  };

  handleScroll = index => {
    const { appContext } = this.props;

    appContext.sectionRefs[index].current.scrollIntoView({
      behavior: `smooth`
    });
  };

  //

  render() {
    const { appContext, frontmatter, markdownRemark } = this.props;

    //

    return (
      <>
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
          pathname="/"
        />

        <ClientRender>
          <Header />
        </ClientRender>

        <Layout className="index-page w-full relative gpu bg-navy text-bone">
          <section
            className="index-page__home grid pb-16 xs:pb-8"
            ref={this.sectionRefs[0]}
          >
            <div className="index-page__home__title grid-end--12 mt-8 mb-8 xs:mb-0">
              <h1
                className="d1 font-bold uppercase animation-appear"
                style={{ animationDelay: `100ms` }}
              >
                {appContext.activeSection === 0 ? `●` : `○`}
                {frontmatter.firstName}
              </h1>
              <h1
                className="d1 font-bold uppercase animation-appear"
                style={{ animationDelay: `200ms` }}
              >
                {frontmatter.middleAndLastName}
              </h1>
            </div>

            <div
              className="index-page__intro f3 grid-end--7 grid-end--xs-12 mt-8 xs:mt-4 animation-appear"
              style={{ animationDelay: `300ms` }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            ></div>
          </section>

          <section
            className="index-page__projects grid pb-8"
            ref={this.sectionRefs[1]}
          >
            <button
              type="button"
              className="d1 font-bold uppercase grid-end--12 mt-8 mb-2 text-left animation-appear"
              style={{ animationDelay: `400ms` }}
              onClick={() => this.handleScroll(1)}
            >
              {appContext.activeSection === 1 ? `●` : `○`}
              PROJECTS
            </button>

            <ul className="grid-end--6 grid-end--xs-12 mt-3 xs:my-0">
              {this.projects &&
                this.projects.length > 0 &&
                this.projects.map((project, index) => {
                  const projectKey = `project-${index}`;

                  return (
                    <li
                      className="flex items-center f1 -ml-2 animation-appear"
                      style={{ animationDelay: `${(index + 5) * 100}ms` }}
                      key={projectKey}
                    >
                      {appContext.device !== `mobile` &&
                        (this.state.activeProjectIndex === index ? `●` : `○`)}

                      <Link
                        to={project.slug}
                        className="index-page__project mb-3 block ml-6 xs:ml-3"
                        onMouseEnter={() => this.handleProjectMouseOver(index)}
                      >
                        <h4 className="f4 font-medium mt-4 xs:mt-2">
                          {project.title}
                        </h4>

                        {appContext.device !== `mobile` && (
                          <>
                            <p className="b1 mt-2 font-medium italic">
                              {project.client}
                            </p>

                            <p className="b2 mt-2">{project.synopsis}</p>
                          </>
                        )}

                        <div className="index-page__home-button b1 text-left flex items-center block py-4 xs:py-3">
                          <span className="whitespace-no-wrap italic">
                            Learn more
                          </span>
                          <span className="index-page__home-button__arrow ml-2">
                            →
                          </span>
                        </div>
                      </Link>
                    </li>
                  );
                })}
            </ul>

            {appContext.device !== `mobile` && (
              <ul className="grid-end--4 grid-start--8 grid-end--xs-12 grid-start--xs-1 relative">
                {this.projects &&
                  this.projects.length > 0 &&
                  this.projects.map((project, index) => {
                    const projectImageKey = `project-image-${index}`;

                    if (
                      project.featuredImage &&
                      project.featuredImage.childImageSharp
                    ) {
                      return (
                        <li
                          key={projectImageKey}
                          className={`index-page__project__image sticky pt-12 mb-12 ${
                            this.state.activeProjectIndex === index
                              ? `animation-appear-from-right block`
                              : `hidden`
                          }`}
                        >
                          <Img
                            fluid={project.featuredImage.childImageSharp.fluid}
                            objectFit="contain"
                            imgStyle={{
                              maxHeight: 600,
                              width: `100%`
                            }}
                          />
                        </li>
                      );
                    }

                    return false;
                  })}
              </ul>
            )}
          </section>

          <section
            className="index-page__experience grid pb-32 xs:pb-16"
            ref={this.sectionRefs[2]}
          >
            <button
              type="button"
              className="d1 font-bold uppercase grid-end--12 my-8 xs:my-4 text-left"
              onClick={() => this.handleScroll(2)}
            >
              {appContext.activeSection === 2 ? `●` : `○`}
              EXPERIENCE
            </button>

            {frontmatter.experience &&
              frontmatter.experience.length > 0 &&
              frontmatter.experience.map((experience, index) => {
                const experienceKey = `experience-${index}`;

                return (
                  <div
                    className="index-page__experience__item grid-end--6 grid-end--xs-12 mb-8"
                    key={experienceKey}
                  >
                    <h4 className="f4 font-medium">{experience.role}</h4>
                    <p className="b1 font-medium italic mt-2">
                      {experience.client} / {experience.dates} /{` `}
                      {experience.location}
                    </p>
                    <p className="b1 mt-2">{experience.description}</p>
                  </div>
                );
              })}

            <h3 className="f3 font-medium grid-end--7 grid-end--xs-12 mt-16 xs:mt-4">
              Want to see my full resume? &nbsp;
              <a className="underline" href="mailto:timothy.b.lea@gmail.com">
                Get in touch
              </a>
              .
            </h3>
          </section>
        </Layout>
      </>
    );
  }
}

const IndexPage = ({ data }) => {
  const appContext = useContext(AppContext);

  const { siteMetadata: metadata } = data.site;
  const { frontmatter } = data.markdownRemark;
  const { allMarkdownRemark, markdownRemark } = data;

  return (
    <IndexPageComponent
      allMarkdownRemark={allMarkdownRemark}
      appContext={appContext}
      frontmatter={frontmatter}
      markdownRemark={markdownRemark}
      metadata={metadata}
    />
  );
};

export default IndexPage;

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            date
            seoDescription
            seoKeywords
            title
            client
            synopsis
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
        firstName
        middleAndLastName
        experience {
          role
          client
          dates
          location
          description
        }
      }
      html
    }
    site {
      siteMetadata {
        title
        description
        keywords
        author
        url
        twitterUsername
      }
    }
  }
`;
